export const environment: any = {
  "production": true,
  "API": "https://api.g3passports.com/api/",
  "client_url": "https://my.govworks.com",
  "visas": true,
  "header": {
    "logo": "https://govworks-email-assets.s3.amazonaws.com/g3p_logo.svg"
  },
  "support": {
    "phone": "8775877301"
  },
  "login": {
    "enabled": false,
    "logo": "https://govworks-email-assets.s3.amazonaws.com/g3_dark_logo.svg",
  },
  "legal_links": {
    "terms": "https://www.g3passports.com/terms-of-use/",
    "privacy": "https://www.g3passports.com/privacy-policy/",
    "refund": "https://www.g3passports.com/terms-of-use/#cancellations"
  },
  "source": {
    "domain": "g3passports",
    "main_website": "https://www.g3passports.com",
    "promo_code": true,
    "name": "G3Passports"
  },
  "footer": {
    "logo": "https://govworks-email-assets.s3.amazonaws.com/g3p_logo.svg",
    "logo_link": "https://www.g3passports.com",
    "copyright": "G3 Global"
  },
  "landing_pages": {
    "passport": {
      "enabled": false
    },
    "visa": {
      "enabled": false
    },
    "root_type": "passport"
  },
  "fedex_logo": true,
  "create_lead": true, 
  "seals": {
    "mcafee": "https://www.mcafeesecure.com/verify?host=g3passports.com"
  },
  "summary_cta_icon": true,
  "livechat": {
    "enabled": true,
    "license_id": "1073051"
  },
  "tags": {
    "analytics": "UA-59617957-1",
    "adwords": "AW-967068442",
    "gtag_conv": "ZfkDCJTQvFkQmpaRzQM",
    "tag_manager": "GTM-KHRZHGX"
  },
  "locations_page": {
    "enabled": false
  }
};